<template>
  <v-card>
    <v-card class="mx-auto mt-10" max-width="500"><!-- elevation-8 -->
      <v-card-text class="mt-5 pa-0">
        <v-layout column>
          <v-layout justify-center class="mt-3">
            <v-card class="mx-auto" flat>
              <v-img height="60" contain :src="require(`../../assets/${logoUrl}`)"></v-img>
            </v-card>
          </v-layout>
          <v-card flat class="pa-2">
            <v-form ref="loginForm" autocomplete="off" class="mt-3">
              <v-text-field
                v-model="model.username"
                outlined
                dense
                append-icon="mdi-account"
                @keyup.enter="loginHandler"
                :rules="$_requiredValidation"
                id="username_field"
                :label="$t('message.login.userName')"
                type="text"
                hide-details
                class="mb-3"
              ></v-text-field>
              <v-text-field
                v-model="model.password"
                outlined
                dense
                id="password_field"
                :rules="$_requiredValidation"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @keyup.enter="loginHandler"
                :label="$t('message.login.password')"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                hide-details
                class="mb-3"
              ></v-text-field>
                <v-text-field
                v-model="hostRef"
                @keyup="doAlbhaNumericConversion"
                outlined
                dense
                @keyup.enter="loginHandler"
                id="domain"
                :rules="$_requiredValidation"
                :label="$t('message.login.host')"
                type="text"
                :suffix="defaultHost"
                :hide-details="!domainHasMistake"
                class="mb-3"
              ></v-text-field>
              <v-select
                append-icon="mdi-translate"
                id="lang_select"
                outlined
                dense
                hide-details
                :label="$t('message.login.language')"
                :items="language"
                v-model="defaultLang"
                item-text="title"
                item-value="value"
              ></v-select>
              <v-layout justify-center class="mt-5">
                <v-btn
                  color="primary"
                  class="mr-4"
                  :loading="loading"
                  @click="loginHandler"
                >{{ $t('message.layout.login') }}</v-btn>
              </v-layout>
            </v-form>
          </v-card>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
/* eslint-disable */ // for local login
export default {
  data () {
    return {
      loading: false,
      model: {
        username: '',
        password: '',
        host: ''
      },
      hostRef: '',
      defaultHost: this.$store.state.common.host,
      defaultLang: window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) ? window.localStorage.getItem(process.env.VUE_APP_LOCALE_STORAGE) : 'no',
      showPassword: false,
      notificationTitle: '',
      notificationImage: '',
      logoUrl: process.env.VUE_APP_LOGO_FILE_NAME,
      domainHasMistake: false
    }
  },
  computed: {
    language () {
      return [
        { title: this.$t('message.common.english'), value: 'en' },
        { title: this.$t('message.common.norwegian'), value: 'no' }
      ]
    },
    $_domainValidation () {
      var letterNumber = /^[0-9a-zA-Z]+$/
      return [
        val => {
          if (!val) {
            this.domainHasMistake= true
            return 'Required'
          }
          if (val.match(letterNumber)) {
            this.domainHasMistake= false
            return true
          } else {
            this.domainHasMistake= true
            return 'Invalid Domain Name'
        }
      }]
    }
  },
  watch: {
    defaultLang () {
      this.$i18n.locale = this.defaultLang
      window.localStorage.setItem(process.env.VUE_APP_LOCALE_STORAGE, this.defaultLang)
    }
  },
  created() {
    this.hostRef = window.localStorage.getItem(process.env.VUE_APP_DOMAIN_CACHE_NAME) ?? ''
  },
  mounted () {
    switch (process.env.VUE_APP_HOST_INCLUDES) {
      case 'axentiv':
        this.notificationTitle = 'AXENTIV'
        this.notificationImage = 'axentiv'
        break
      case 'weldit':
        this.notificationTitle = 'WELD IT'
        this.notificationImage = 'weldit'
        break
      case 'novap':
        this.notificationTitle = 'NOVAP'
        this.notificationImage = 'novap'
        break
      default:
        break
    }
    /* if (this.defaultHost.includes(process.env.VUE_APP_HOST_INCLUDES)) {
      this.notificationTitle = 'AXENTIV'
      this.notificationImage = 'axentiv'
    } else {
      this.notificationTitle = 'WELD IT'
      this.notificationImage = 'weldit'
    } */
  },
  methods: {
    doAlbhaNumericConversion () {
      if (this.hostRef.length > 13) this.hostRef = this.hostRef.substring(0, 13)
      this.hostRef = this.hostRef.replace(/[^A-Za-z1-9]/g, "").toLowerCase()
    },
    loginHandler () {
      if (this.$refs.loginForm.validate()) {
        window.localStorage.removeItem('TimerPwa_weekno')
        window.localStorage.removeItem('TimerPwa_year')
        this.loading = true
        let hostRefString = this.hostRef.toLowerCase()
        hostRefString = hostRefString.replace(/[^0-9a-zA-Z]/gi, '') // to trim the space and spection characters
        if (!hostRefString.includes(this.defaultHost)) {
          this.model.host = ''
          this.model.host += `${hostRefString}${this.defaultHost}`
        } else this.model.host = `${hostRefString}`
        const authObj = { email: this.model.username, password: this.model.password }
        this.$api.execute('post', `https://${this.model.host}/api/login`, authObj).then(reponse => {
          if (reponse && reponse.data) {
            const responseData = reponse.data
            if (responseData.statusCode === 500) {
              let message = responseData[`${this.$i18n.locale}_message`] || responseData.message
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: message }) // responseData[`${this.$i18n.locale}_message`]
              this.loading = false
            } else {
              var date = new Date()
              date.setDate(date.getDate() + 4)
              this.model.password = reponse.data.password
              this.model.username = reponse.data.user.email
              this.model.hostAppToken = reponse.data.token
              const timerPwaAppUser = reponse.data.user.user_id
              this.$cookie.set(process.env.VUE_APP_HOST_USER, timerPwaAppUser, { expires: date })
              this.$cookie.set(process.env.VUE_APP_HOST_TOKEN, reponse.data.token, { expires: date })
              this.$cookie.set(process.env.VUE_APP_CHECKLIST_ACCESS_TOKEN, reponse.data.checklist_access_token, { expires: date })
              /* set user permission and roles  */
              let obj = JSON.stringify({ permissions: reponse.data.permissions || {} , role: reponse.data.role || {} })
              if (obj){
                this.$cookie.set(process.env.VUE_APP_PERMISSIONS_AND_ROLES, this.$formatter.encryptString(obj), { expires: date })
                this.$store.commit('setPermissions', JSON.parse(obj))
              }
              /* set the taskcount in cookie and read it anywhere */
              window.localStorage.setItem(process.env.VUE_APP_TASKS_COUNT, reponse.data.task_count)
              // this.$cookie.set(process.env.VUE_APP_TASKS_COUNT, reponse.data.task_count || 0, { expires: date })
              /* set initial with model and get from there */
              this.model.initialer = responseData.user.initialer || ''
              /* The app login begins from here */
              this.$store.dispatch('login', this.model)
                .then((userObj) => {
                  const timerStartup = userObj.timer_startup
                  /* On sucess Login store the domain */
                  window.localStorage.setItem(process.env.VUE_APP_DOMAIN_CACHE_NAME, hostRefString)
                  /* var date = new Date()
                  date.setDate(date.getDate() + 4)
                  this.$api.execute('get', 'generalsettings')
                    .then((response) => {
                      if (response.data && response.data[0]) {
                        const model = response.data[0]
                        const isProjectExcluded = model.exclude_project_module !== undefined && model.exclude_project_module !== null ? model.exclude_project_module : false
                        const isDeviationExcluded = model.exclude_deviation_module !== undefined && model.exclude_deviation_module !== null ? model.exclude_deviation_module : false
                        this.$store.commit('isProjectExcluded', isProjectExcluded)
                        this.$store.commit('isDeviationExcluded', isDeviationExcluded)
                        const obj = { project: isProjectExcluded ? 1 : 0, deviation: isDeviationExcluded ? 1 : 0, expires_at: date }
                        this.$cookie.set('pwaAppExcludeModules', JSON.stringify(obj), { expires: date })
                      }
                    }) */
                  const self = this
                  try {
                    if (('Notification' in window)) {
                      Notification.requestPermission().then(function (result) {
                        self.$api.execute('get', `hours/get_not_approved_entries_count/${userObj.id}`)
                          .then((res) => {
                            const weekDetails = res.data
                            let showWeekDetails = ''
                            if (weekDetails.length > 0) {
                              for (let i = 0; i < weekDetails.length; i++) {
                                if (weekDetails.length === (i + 1)) showWeekDetails += weekDetails[i]
                                else showWeekDetails += weekDetails[i] + ' , '
                              }
                              if ('serviceWorker' in navigator) {
                                navigator.serviceWorker.getRegistration().then(function (reg) {
                                  var options = {
                                    body: self.$t('message.common.not_approved_notification', { count: weekDetails.length, weeknumber: showWeekDetails }),
                                    icon: require(`../../../public/img/${self.notificationImage}.png`)
                                  }
                                  if (reg) {
                                    reg.showNotification(self.notificationTitle, options)
                                  }
                                })
                              }
                            }
                          })
                      })
                      this.$eventBus.$emit('checkHoursExceeding')
                    }
                  } catch (error) {}
                  /* get and set features */
                  this.$hostAppApi.execute('get', `https://${this.model.host}/api/check_and_get_tenant_features?user_id=${timerPwaAppUser}`, { headers: { language: this.$i18n.locale } })
                    .then((response) => {
                      if (response && response.data && response.data.features) {
                        window.localStorage.setItem(process.env.VUE_APP_FEATURES_LIST, this.$formatter.encryptString(JSON.stringify(response.data.features)))
                      }
                    })
                    .finally(() => {
                      timerStartup === 1 ? this.$router.push('/calendar') : timerStartup === 2 ? this.$router.push('/stemp') : this.$router.push('/dashboard')
                    })
                })
                .finally(() => {
                  this.loading = false
                })
            }
          }
        }).catch((e) => {
          console.log('Host app api login exception:' +e)
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.login.authFailed') })
          this.loading = false
        })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    }
  }
}
</script>
<style>
.border-styles {
  border-top-right-radius: 40px !important;
  border-bottom-left-radius: 40px !important;
}
.login .v-sheet--offset {
  top: -30px;
  position: relative;
}
</style>
